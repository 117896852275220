import React from 'react';
import {FaPercentage, FaCheck, FaTimes } from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const LandClassification = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'N/A';
  };

  // Helper function to format percentages
  const formatPercentage = (num) => {
    return num != null && !isNaN(num) ? `${(num * 100).toFixed(1)}%` : 'N/A';
  };

  const attributes = [
    { 
      label: "Operable (Ha)", 
      value: formatNumber(data.operableHa), 
      icon: FaCheck, 
      unit: "Hectare",
      color: 'text-green-600',
    },
    { 
      label: "% Operable", 
      value: formatPercentage(data.percentageOperable), 
      icon: FaPercentage, 
      unit: "",
      color: 'text-green-600',
    },
    { 
      label: "Non Operable (Ha)", 
      value: formatNumber(data.nonOperableHa), 
      icon: FaTimes, 
      unit: "Hectare",
      color: 'text-red-600',
    },
    { 
      label: "% Non Operable", 
      value: formatPercentage(data.percentageNonOperable), 
      icon: FaPercentage, 
      unit: "",
      color: 'text-red-600',
    },
  ];

  return (
    <FoldableSection title="Land Classification">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {attributes.map((attribute, index) => (
          <AttributeItem key={index} {...attribute} />
        ))}
      </div>
      <CommentBlock content={data.commentsLandClassification} />
    </FoldableSection>
  );
};

export default LandClassification;