import React from 'react';
import { FaDollarSign, FaPercentage } from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const PriceAssessment = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'N/A';
  };

  // Helper function to format percentages
  const formatPercentage = (num) => {
    return num != null && !isNaN(num) ? `${(num * 100).toFixed(1)}%` : 'N/A';
  };

  return (
    <FoldableSection title="Price Assessment">
      <h3 className="text-lg font-semibold mb-2">Asking Price</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <AttributeItem
          label="Asking Price (CAD)"
          value={formatNumber(data.askingPrice)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Asking Price/Ha (CAD)"
          value={formatNumber(data.askingPricePerHa)}
          icon={FaDollarSign}
          unit="CAD/Ha"
        />
      </div>

      <h3 className="text-lg font-semibold mb-2">Value Breakdown</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <AttributeItem
          label="Bare Land Value"
          value={formatNumber(data.bareLandValue)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Forest"
          value={formatNumber(data.forestValue)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Merchantable Volume"
          value={formatNumber(data.merchantableVolumeValue)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Road Network"
          value={formatNumber(data.roadNetworkValue)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Additional Development / Recreational Value"
          value={formatNumber(data.additionalDevelopmentValue)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Contiguity Value Add"
          value={formatNumber(data.contiguityValueAdd)}
          icon={FaDollarSign}
          unit="CAD"
        />
      </div>

      <h3 className="text-lg font-semibold mb-2">Valuation</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <AttributeItem
          label="Average Forest Value (CAD)"
          value={formatNumber(data.averageForestValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
          borderColor="border-blue-600"
        />
        <AttributeItem
          label="Average Value Per Ha (CAD)"
          value={formatNumber(data.averageValuePerHa)}
          icon={FaDollarSign}
          unit="CAD/Ha"
          color="text-blue-600"
          borderColor="border-blue-600"
        />
        <AttributeItem
          label="Upper Value (CAD)"
          value={formatNumber(data.upperValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-green-600"
        />
        <AttributeItem
          label="Upper Value Per Ha (CAD)"
          value={formatNumber(data.upperValuePerHa)}
          icon={FaDollarSign}
          unit="CAD/Ha"
          color="text-green-600"
        />
        <AttributeItem
          label="Lower Value (CAD)"
          value={formatNumber(data.lowerValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-red-600"
        />
        <AttributeItem
          label="Lower Value Per Ha (CAD)"
          value={formatNumber(data.lowerValuePerHa)}
          icon={FaDollarSign}
          unit="CAD/Ha"
          color="text-red-600"
        />
        <AttributeItem
          label="Average w/o Rec Value (CAD)"
          value={formatNumber(data.averageWorecValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
          borderColor="border-blue-600"
        />
        <AttributeItem
          label="Average w/o Rec Value Per Ha (CAD)"
          value={formatNumber(data.averageWorecValuePerHa)}
          icon={FaDollarSign}
          unit="CAD/Ha"
          color="text-blue-600"
          borderColor="border-blue-600"
        />
        <AttributeItem
          label="Rec Value Percentage"
          value={formatPercentage(data.recValuePercentage)}
          icon={FaPercentage}
          unit=""
        />
      </div>

      <CommentBlock content={data.valuationSummary} />
    </FoldableSection>
  );
};

export default PriceAssessment;