import React from 'react';
import Select from 'react-select';
import { theme } from '../../../styles/index';

export default function LandownerFilter({
  selectedLandowners,
  onLandownerChange,
  landownerOptions,
  size = 'medium',
  error = null,
  style = {}
}) {

  const currentSize = theme.sizes[size] || theme.sizes.medium;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: 'rgba(255, 255, 255, 0.8)',
      border: 'none',
      borderRadius: theme.common.borderRadius,
      minHeight: currentSize.height,
      width: '300px',
    }),
    menu: (provided) => ({
      ...provided,
      background: 'white',
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.primary : state.isFocused ? 'rgba(0, 0, 0, 0.05)' : 'white',
      color: state.isSelected ? 'white' : theme.colors.text.primary,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.background.muted,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: theme.colors.text.primary,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ':hover': {
        backgroundColor: theme.colors.primary,
        color: 'white',
      },
    }),
  };

  const handleLandownerChange = (selected) => {
    onLandownerChange(selected || []);
  };

  return (
    <div style={ {
      ...theme.common.glassmorphism,
      borderRadius: theme.common.borderRadius,
      display: 'inline-flex',
      position: 'relative',
      ...style
    } }>
      <Select
        isMulti
        value={ selectedLandowners }
        onChange={ handleLandownerChange }
        options={ landownerOptions }
        placeholder="Filter by landowner..."
        styles={ customStyles }
      />
      { error && (
        <div style={ {
          color: theme.colors.text.error,
          fontSize: currentSize.fontSize,
          marginTop: '8px',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '4px 8px',
          borderRadius: theme.common.borderRadius,
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
        } }>
          { error }
        </div>
      ) }
    </div>
  );
} 