import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { theme } from '../../../../styles/index.js';
import { chartOptions } from './ChartConfig';

const ChartCard = styled.div`
  background: ${theme.common.glassmorphism.background};
  backdrop-filter: ${theme.common.glassmorphism.backdropFilter};
  box-shadow: ${theme.common.glassmorphism.boxShadow};
  padding: ${theme.common.spacing.xl};
  border-radius: 8px;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.lg};
`;

const Title = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.xs};
`;

const Subtitle = styled.p`
  color: ${theme.colors.text.muted};
  font-size: 0.875rem;
`;

const ChartContainer = styled.div`
  height: 300px;
  position: relative;
`;

const LegendContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.common.spacing.md};
  margin-top: ${theme.common.spacing.xl};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  font-size: 0.875rem;
  color: ${theme.colors.text.muted};
`;

const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: ${props => props.color};
`;

const BudgetDistribution = ({ categories }) => {
  const parseBudget = (budgetStr) => {
    if (!budgetStr) return 0;
    const num = parseFloat(budgetStr.replace(/[^0-9.]/g, ''));
    if (budgetStr.includes('K')) return num * 1000;
    if (budgetStr.includes('M')) return num * 1000000;
    return num;
  };

  const categoryBudgets = Object.values(categories).map(category => {
    let budget = 0;
    if (category.metrics.budget) {
      budget = parseBudget(category.metrics.budget);
    } else if (category.metrics.totalSecured) {
      budget = parseBudget(category.metrics.totalSecured);
    }
    return {
      label: category.label,
      value: budget,
      color: category.color
    };
  });

  const totalBudget = categoryBudgets.reduce((sum, cat) => sum + cat.value, 0);

  const distributionData = {
    labels: categoryBudgets.map(c => c.label),
    datasets: [{
      data: categoryBudgets.map(c => c.value),
      backgroundColor: categoryBudgets.map(c => c.color),
      borderWidth: 0,
      hoverOffset: 4
    }]
  };

  const options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const percentage = ((value / totalBudget) * 100).toFixed(1);
            return `${context.label}: ${new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              notation: 'compact',
              compactDisplay: 'short'
            }).format(value)} (${percentage}%)`;
          }
        }
      }
    },
    scales: {}
  };

  return (
    <ChartCard>
      <Header>
        <Title>Budget Distribution</Title>
        <Subtitle>Allocation across different categories</Subtitle>
      </Header>
      
      <ChartContainer>
        <Doughnut data={distributionData} options={options} />
      </ChartContainer>

      <LegendContainer>
        {categoryBudgets.map((category, index) => (
          <LegendItem key={index}>
            <LegendColor color={category.color} />
            <span>{category.label} ({((category.value / totalBudget) * 100).toFixed(1)}%)</span>
          </LegendItem>
        ))}
      </LegendContainer>
    </ChartCard>
  );
};

export default BudgetDistribution;
