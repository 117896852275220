/**
 * Notion Service
 * 
 * This module provides functions to interact with the Notion API through our backend server.
 * It includes error handling, logging for debugging purposes, and dedicated parsing functions for different database types.
 */

export const getApiUrl = (path) => {
  // Return only the path for relative URL
  return path;
};

/**
 * Queries the Notion database through our backend server
 * @param {string} databaseId - The ID of the Notion database to query
 * @param {Object} [filter] - The filter to apply to the query (optional)
 * @returns {Promise<Array>} A promise that resolves to an array of database items
 * @throws {Error} If there's any problem fetching the data
 */
export const queryNotionDatabase = async (databaseId, filter) => {
  try {
    console.log('Querying Notion database...');
    console.log('Database ID:', databaseId);
    console.log('Filter:', filter);
    console.log('API URL:', getApiUrl('/api/query-notion'));

    const response = await fetch(getApiUrl('/api/query-notion'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ databaseId, filter }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    console.log('Received data from Notion:', data);
    return data;
  } catch (error) {
    console.error('Error querying Notion database:', error);
    throw error;
  }
};

/**
 * Retrieves a specific Notion page through our backend server
 * @param {string} pageId - The ID of the Notion page to retrieve
 * @returns {Promise<Object>} A promise that resolves to the page data
 * @throws {Error} If there's any problem fetching the data
 */
export const retrieveNotionPage = async (pageId) => {
  try {
    console.log('Retrieving Notion page...');
    console.log('Page ID:', pageId);
    console.log('API URL:', getApiUrl(`/api/retrieve-notion-page/${pageId}`));
    const response = await fetch(getApiUrl(`/api/retrieve-notion-page/${pageId}`), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    console.log('Received page data from Notion:', data);
    return data;
  } catch (error) {
    console.error('Error retrieving Notion page:', error);
    throw error;
  }
};

/**
 * Parses forest data from the Notion database response
 * @param {Array} data - The raw data received from the Notion database
 * @returns {Array} An array of parsed forest objects
 */
export const parseForestData = (data) => {
  return data.map(item => ({
    id: item.id,
    name: item.properties.Name?.title[0]?.plain_text || 'Unnamed Forest',
    location: item.properties.Location?.rich_text[0]?.plain_text || 'Unknown Location',
    area: item.properties.Area?.number || 0,
    treeSpecies: item.properties['Tree Species']?.multi_select.map(species => species.name) || [],
    images: item.properties['Images']?.relation || [],
    description: item.properties.Description?.rich_text[0]?.plain_text || '',
    carbonCredits: item.properties['Carbon Credits']?.number || 0,
    annualRainfall: item.properties['Annual Rainfall']?.number || 0,
    elevation: item.properties.Elevation?.number || 0,
    soilType: item.properties['Soil Type']?.select?.name || 'Unknown',
    conservationStatus: item.properties['Conservation Status']?.select?.name || 'Unknown',
  }));
};

/**
 * Parses image data from a Notion page
 * @param {Object} pageData - The data of a Notion page
 * @returns {Array} An array of image URLs
 */
export const parseImageData = (pageData) => {
  return pageData.properties.Image?.files
    .map(file => file.file?.url)
    .filter(url => url != null) || [];
};

/**
 * Fetches and parses forest data from the Notion database
 * @param {string} databaseId - The ID of the Notion database to query
 * @returns {Promise<Array>} A promise that resolves to an array of parsed forest objects
 */
export const fetchAndParseForestData = async (databaseId) => {
  const rawData = await queryNotionDatabase(databaseId);
  return parseForestData(rawData);
};

/**
 * Fetches and parses image data for a forest
 * @param {Array} images - An array of image page IDs
 * @returns {Promise<Array>} A promise that resolves to an array of image URLs
 */
export const fetchAndParseForestImages = async (images) => {
  const imageUrls = [];
  for (const doc of images) {
    const pageData = await retrieveNotionPage(doc.id);
    imageUrls.push(...parseImageData(pageData));
  }
  return imageUrls;
};

/**
 * Parses due diligence data from Notion database results
 * @param {Array} rawData - The raw data from the Notion database query
 * @returns {Array} An array of parsed due diligence objects
 */
export const parseDueDiligenceData = (rawData) => {
  return rawData.map(item => ({
    // Website Settings
    viewability: item.properties['Viewability']?.select?.name || 'Unknown',
    
    // Dates
    startDate: item.properties.Assessment_start_date?.date?.start || null,
    endDate: item.properties.Assessment_end_date?.date?.start || null,
    dueDate: item.properties.Due_Date?.date?.start || null,

    // Banner information
    purchasePrice: item.properties['Purchase Price']?.number || 0,

    // Team information
    projectLeader: parseTeamMembers(item.properties.Project_Leader?.people),
    workingTeam: parseTeamMembers(item.properties.Working_Team?.people),

    // Base information
    id: item.id,
    uniqueId: item.properties['ID']?.unique_id?.number || null,
    name: item.properties.Transaction_Name?.title[0]?.plain_text || 'Unnamed Due Diligence',
    status: item.properties.Status?.status?.name || 'Unknown',
    level: item.properties.Level?.select?.name || 'Unknown',
    webmap_id: item.properties.webmap_id?.rich_text[0]?.plain_text || '',
    webmap_coordinates: item.properties.webmap_coordinates?.rich_text[0]?.plain_text || '',
    imageUrl: item.properties.ImageUrl?.rich_text[0]?.plain_text || 'https://images.unsplash.com/photo-1473448912268-2022ce9509d8?q=80&w=3241&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    // Access & Size Section
    coordinates: item.properties['Property Coordinates']?.rich_text[0]?.plain_text || '',
    physicalAccess: item.properties.Physical_Access?.rich_text[0]?.plain_text || '',
    rightOfWay: item.properties.ROW?.rich_text[0]?.plain_text || '',
    propertySizeGIS: item.properties['Property size GIS (Ha)']?.rollup?.number || 0,
    propertySizePOL: item.properties['Property size POL (Ha)']?.rollup?.number || 0,
    propertySizeSeller: item.properties['Property Size Seller (Ha)']?.number || 0,
    commentsAccessibilityRoadNetwork: item.properties.Accessibility_Road_Network_Comments?.rich_text[0]?.plain_text || '',

    // Land Classification Section
    operableHa: item.properties['Operable (Ha)']?.formula?.number || 0,
    percentageOperable: item.properties['Percentage_Operable']?.formula?.number || 0,
    nonOperableHa: item.properties['Non Operable (Ha)']?.rollup?.number || 0,
    percentageNonOperable: item.properties['Percentage_Non_Operable']?.formula?.number || 0,
    commentsLandClassification: item.properties.Comments_Land_Classification?.rich_text[0]?.plain_text || '',

    // Bare Land Value Section
    operableValue: item.properties['Operable Value (CAD/Ha)']?.formula?.number || 0,
    nonOperableValue: item.properties['Non Operable Value (CAD/Ha)']?.formula?.number || 0,
    totalOperableValue: item.properties['Total Operable Value (CAD)']?.formula?.number || 0,
    totalNonOperableValue: item.properties['Total Non Operable Value (CAD)']?.formula?.number || 0,
    totalBareLandValue: item.properties['Total (CAD) Bare Land Value']?.formula?.number || 0,
    commentsBareValue: item.properties.Comments_Bare_Land_Value?.rich_text[0]?.plain_text || '',

    // Contiguity Value Add Section
    contiguityValueAdd: item.properties['Contiguity Value Add']?.formula?.number || 0,
    totalContiguityValueAdd: item.properties['Total (CAD) Contiguity Value Add']?.formula?.number || '',
    commentsContiguityValueAdd: item.properties.Comments_Contiguity_Value_Add?.rich_text[0]?.plain_text || '',

    // Forest & Stumpage Value Section
    percentageSW: item.properties['% SW']?.formula?.number || 0,
    percentageHW: item.properties['% HW']?.formula?.number || 0,
    untreatedHa: item.properties['Untreated (Ha)']?.rollup?.number || 0,
    totalUntreated: item.properties['Total Untreated (CAD)']?.formula?.number || 0,
    treatedHa: item.properties['Treated (Ha)']?.rollup?.number || 0,
    plantingHa: item.properties['Planting (Ha)']?.number || 0,
    eccHa: item.properties['ECC (ha)']?.rollup?.number || 0,
    pctHa: item.properties['PCT (Ha)']?.number || 0,
    totalTreatedValue: item.properties['Total Treated (CAD)']?.rollup?.number || 0,
    totalForestValue: item.properties['Total (CAD) Forest']?.formula?.number || 0,
    swMton: item.properties['SW (mton)']?.rollup?.number || 0,
    hwMton: item.properties['HW (mton)']?.rollup?.number || 0,
    totalVolume: item.properties['Total Volume']?.rollup?.number || 0,
    mtonHaOpLand: item.properties['mton/ha op. land']?.formula?.number || 0,
    stumpageSW: item.properties['Stumapge SW (CAD)']?.formula?.number || 0,
    stumpageHW: item.properties['Stumpage HW (CAD)']?.formula?.number || 0,
    stumpageValue: item.properties['Stumpage Value (CAD)']?.rollup?.number || 0,
    commentsForest: item.properties.Forest_Comments?.rich_text[0]?.plain_text || '',
    commentsMerchantableVolume: item.properties.Comments_Merchantable_Volume?.rich_text[0]?.plain_text || '',

    // Next Steps Section
    next_steps: item.properties.Implications_Next_Steps?.rich_text[0]?.plain_text || '',
    recommendation: item.properties['Recommendation']?.select?.name || 'Pending',

    // Price Assessment Section
    askingPrice: item.properties['Asking Price (CAD)']?.number || 0,
    askingPricePerHa: item.properties['Asking Price/Ha (CAD)']?.formula?.number || 0,
    bareLandValue: item.properties['Bare Land Value (CAD)']?.formula?.number || 0,
    forestValue: item.properties['Forest Value']?.formula?.number || 0,
    merchantableVolumeValue: item.properties['Merchantable Volume Value']?.formula?.number || 0,
    roadNetworkValue: item.properties['Road Network Value']?.formula?.number || 0,
    additionalDevelopmentValue: item.properties['Additional Development / Recreational Value (CAD)']?.formula?.number || 0,
    averageForestValue: item.properties['Average Forest Value (CAD)']?.formula?.number || 0,
    averageValuePerHa: item.properties['Average Forest Value Per Ha (CAD)']?.formula?.number || 0,
    upperValue: item.properties['Upper Value (CAD)']?.formula?.number || 0,
    upperValuePerHa: item.properties['Upper Value Per Ha (CAD)']?.formula?.number || 0,
    lowerValue: item.properties['Lower Value (CAD)']?.formula?.number || 0,
    lowerValuePerHa: item.properties['Lower Value Per Ha (CAD)']?.formula?.number || 0,
    averageWorecValue: item.properties['Average w/o Rec Value (CAD)']?.formula?.number || 0,
    averageWorecValuePerHa: item.properties['Average w/o Rec Value Per Ha (CAD)']?.formula?.number || 0,
    recValuePercentage: item.properties['Average w/o Rec Value Rec Value Percentage']?.formula?.number || 0,
    valuationSummary: item.properties['Valuation Summary']?.rich_text[0]?.plain_text || '',

    // Road Network Section
    lengthPickupRoads: item.properties['Length Pickup Roads (m)']?.rollup?.number || 0,
    lengthLogTruckRoads: item.properties['Length Log Truck Roads (m)']?.rollup?.number || 0,
    totalPickupRoads: item.properties['Total (CAD) Pickup Roads']?.formula?.number || 0,
    totalLogTruckRoads: item.properties['Total (CAD) Log Truck Roads']?.formula?.number || 0,
    commentsRoadNetwork: item.properties.Comments_Road_Network?.rich_text[0]?.plain_text || '',

    // Additional Development Value Section
    waterFrontageValue: item.properties['Water Frontage Value (CAD)']?.formula?.number || 0,
    publicRoadFrontageDevelopment: item.properties['Public Road Frontage Development (CAD)']?.rollup?.number || 0,
    landBaseValue: item.properties['Land Base Value (CAD)']?.rollup?.number || 0,
    recreationalValue: item.properties['Recreational Value (CAD)']?.formula?.number || 0,
    commentsAdditionalDevelopmentValue: item.properties.Comments_Additional_Development_Value?.rich_text[0]?.plain_text || '',

    // Additional Information
    disclaimer: item.properties['Disclaimer']?.rich_text[0]?.plain_text || '',
    facts: item.properties['Facts']?.rich_text[0]?.plain_text || '',
    client: item.properties['Client']?.relation || [],
    pids: item.properties['🔢 PIDs']?.relation || [],
    url: item.url || '',
    publicUrl: item.public_url || null
  }));
};

// Helper function to parse team members
const parseTeamMembers = (people = []) => {
  return people?.map(person => ({
    id: person.id,
    name: person.name,
    email: person.person?.email
  })) || [];
};

/**
 * Fetches and parses due diligence data from the Notion database
 * @param {string} databaseId - The ID of the Notion database to query
 * @returns {Promise<Array>} A promise that resolves to an array of due diligence objects with id and name
 */
export const fetchAndParseDueDiligenceData = async (databaseId) => {
  const rawData = await queryNotionDatabase(databaseId);
  return parseDueDiligenceData(rawData);
};

/**
 * Add a new user to the Notion database
 * @param {Object} user - User object from Descope
 * @returns {Promise} Notion API response
 */
export const addUserToNotion = async (user) => {
  try {
    const userData = {
      email: user.email,
      name: user.name || user.email.split('@')[0],
      userId: user.userId,
      signUpDate: new Date().toISOString(),
    };

    const response = await fetch('/api/notion/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Failed to add user to Notion');
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding user to Notion:', error);
    throw error;
  }
};

// Add this new function
export const getUserPermissions = async (email) => {
  try {
    const response = await fetch('/api/getUserPermissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch user permissions');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    throw error;
  }
};
