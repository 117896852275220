import { theme } from '../../../../styles/index.js';

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
        padding: 20,
        font: {
          family: theme.fonts.body,
          size: 12
        }
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      padding: 12,
      backgroundColor: theme.colors.background.light,
      borderColor: theme.colors.border.light,
      borderWidth: 1,
      titleColor: theme.colors.text.dark,
      bodyColor: theme.colors.text.primary,
      titleFont: {
        family: theme.fonts.heading,
        size: 14,
        weight: 'bold'
      },
      bodyFont: {
        family: theme.fonts.body,
        size: 12
      },
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(context.parsed.y);
          }
          return label;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: theme.colors.border.light,
        drawBorder: false
      },
      ticks: {
        padding: 10,
        font: {
          family: theme.fonts.body,
          size: 11
        },
        callback: value => new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          notation: 'compact',
          compactDisplay: 'short'
        }).format(value)
      }
    },
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        padding: 10,
        font: {
          family: theme.fonts.body,
          size: 11
        }
      }
    }
  }
};
