import React, { useState, useEffect } from 'react';
import { useSession } from '@descope/react-sdk';
import { queryNotionDatabase, retrieveNotionPage, getUserPermissions } from '../../services/notionService';
import { queryAirtableBase, retrieveAirtableRecord } from '../../services/airtableService';

/**
 * NotionDebug component for querying Notion databases, retrieving pages, and displaying raw responses.
 * This component provides a clean, structured interface for debugging Notion queries and page retrievals.
 */
const ApiTesting = () => {
  const { session } = useSession();
  const [databaseId, setDatabaseId] = useState('');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (session?.user?.email) {
      setUserEmail(session.user.email);
    }
  }, [session]);
  const [pageId, setPageId] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [airtableBaseId, setAirtableBaseId] = useState('');
  const [airtableTableId, setAirtableTableId] = useState('');
  const [airtableRecordId, setAirtableRecordId] = useState('');
  const [airtableFilter, setAirtableFilter] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [permissionResponse, setPermissionResponse] = useState('');

  const handlePermissionTest = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setPermissionResponse('');

    try {
      const result = await getUserPermissions(userEmail);
      setPermissionResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error fetching user permissions: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDatabaseQuery = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      let parsedFilter = filter ? JSON.parse(filter) : {};
      const result = await queryNotionDatabase(databaseId, parsedFilter);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error querying Notion database: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageRetrieval = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      const result = await retrieveNotionPage(pageId);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error retrieving Notion page: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAirtableQuery = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      const result = await queryAirtableBase(airtableBaseId, airtableTableId, airtableFilter);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error querying Airtable base: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAirtableRecordRetrieval = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      const result = await retrieveAirtableRecord(airtableBaseId, airtableTableId, airtableRecordId);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error retrieving Airtable record: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="api-testing">
      <h1>Custom Data Requests</h1>
      
      <div className="debug-section">
        <h2>Query Notion Due Diligence Database</h2>
        <form onSubmit={handleDatabaseQuery}>
          <div className="form-group">
            <label htmlFor="databaseId">Database ID</label>
            <input
              type="text"
              id="databaseId"
              value={databaseId}
              onChange={(e) => setDatabaseId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="filter">Filter (optional, JSON format)</label>
            <textarea
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder='e.g. {"property": "Name", "text": {"contains": "Forest"}}'
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Querying...' : 'Query Database'}
          </button>
        </form>
      </div>

      <div className="debug-section">
        <h2>Retrieve Notion Due Diligence Page</h2>
        <form onSubmit={handlePageRetrieval}>
          <div className="form-group">
            <label htmlFor="pageId">Page ID</label>
            <input
              type="text"
              id="pageId"
              value={pageId}
              onChange={(e) => setPageId(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Retrieving...' : 'Retrieve Page'}
          </button>
        </form>
      </div>

      <div className="debug-section">
        <h2>Query Airtable Base</h2>
        <form onSubmit={handleAirtableQuery}>
          <div className="form-group">
            <label htmlFor="airtableBaseId">Base ID</label>
            <input
              type="text"
              id="airtableBaseId"
              value={airtableBaseId}
              onChange={(e) => setAirtableBaseId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="airtableTableId">Table ID</label>
            <input
              type="text"
              id="airtableTableId"
              value={airtableTableId}
              onChange={(e) => setAirtableTableId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="airtableFilter">Filter (optional, JSON format)</label>
            <textarea
              id="airtableFilter"
              value={airtableFilter}
              onChange={(e) => setAirtableFilter(e.target.value)}
              placeholder='e.g. {Status}="Active"'
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Querying...' : 'Query Airtable'}
          </button>
        </form>
      </div>

      <div className="debug-section">
        <h2>Retrieve Airtable Record</h2>
        <form onSubmit={handleAirtableRecordRetrieval}>
          <div className="form-group">
            <label htmlFor="airtableBaseId">Base ID</label>
            <input
              type="text"
              id="airtableBaseId"
              value={airtableBaseId}
              onChange={(e) => setAirtableBaseId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="airtableTableId">Table ID</label>
            <input
              type="text"
              id="airtableTableId"
              value={airtableTableId}
              onChange={(e) => setAirtableTableId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="airtableRecordId">Record ID</label>
            <input
              type="text"
              id="airtableRecordId"
              value={airtableRecordId}
              onChange={(e) => setAirtableRecordId(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Retrieving...' : 'Retrieve Record'}
          </button>
        </form>
      </div>

      <div className="debug-section">
        <h2>Test User Permissions</h2>
        <form onSubmit={handlePermissionTest}>
          <div className="form-group">
            <label htmlFor="userEmail">User Email</label>
            <input
              type="email"
              id="userEmail"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Fetching...' : 'Test Permissions'}
          </button>
        </form>
      </div>

      {error && <div className="error">{error}</div>}
      {(response || permissionResponse) && (
        <div className="response">
          <h2>Response</h2>
          <pre>{response}</pre>
        </div>
      )}
    </div>
  );
};

export default ApiTesting;
