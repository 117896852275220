import React from 'react';
import {FaDollarSign} from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const BareLandValue = ({ data }) => {
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'N/A';
  };

  return (
    <FoldableSection title="Bare Land Value">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <AttributeItem 
          label="Operable (CAD/Ha)" 
          value={formatNumber(data.operableValue)} 
          icon={FaDollarSign} 
          unit="CAD/Hectare" 
          color="text-green-600" 
        />
        <AttributeItem 
          label="Total Operable (CAD)" 
          value={formatNumber(data.totalOperableValue)} 
          icon={FaDollarSign} 
          unit="CAD" 
          color="text-green-600" 
        />
        <AttributeItem 
          label="Non Operable (CAD/Ha)" 
          value={formatNumber(data.nonOperableValue)} 
          icon={FaDollarSign} 
          unit="CAD/Hectare" 
          color="text-red-600" 
        />
        <AttributeItem 
          label="Total Non Operable (CAD)" 
          value={formatNumber(data.totalNonOperableValue)} 
          icon={FaDollarSign} 
          unit="CAD" 
          color="text-red-600" 
        />
        <AttributeItem 
          label="Total (CAD)" 
          value={formatNumber(data.totalBareLandValue)} 
          icon={FaDollarSign} 
          unit="CAD" 
          color="text-blue-600" 
          borderColor="border-blue-600" 
        />
      </div>
      <CommentBlock content={data.commentsBareValue} />
    </FoldableSection>
  );
};

export default BareLandValue;