import React from 'react';
import { FaRoad, FaRulerCombined, FaPercentage } from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const AccessAndSize = ({ data }) => {
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'N/A';
  };

  const formatPercentage = (num) => {
    return num != null && !isNaN(num) ? `${(num * 100).toFixed(2)}%` : 'N/A';
  };

  const sectionData = {
    "ROW": [data.rightOfWay, ""],
    "Physical Access": [data.physicalAccess, ""],
    "Property size POL (Ha)": [data.propertySizePOL, "Hectare"],
    "Property size GIS (Ha)": [data.propertySizeGIS, "Hectare"],
    "Delta": [
      data.propertySizePOL && data.propertySizeGIS
        ? (data.propertySizeGIS - data.propertySizePOL) / data.propertySizePOL
        : null,
      ""
    ],
    "Comments": [data.commentsAccessibilityRoadNetwork || "", ""],
  };

  const attributes = [
    { label: "Property size POL (Ha)", value: formatNumber(sectionData["Property size POL (Ha)"][0]), icon: FaRulerCombined, unit: sectionData["Property size POL (Ha)"][1] },
    { label: "Property size GIS (Ha)", value: formatNumber(sectionData["Property size GIS (Ha)"][0]), icon: FaRulerCombined, unit: sectionData["Property size GIS (Ha)"][1] },
  ];

  // Only add Delta if the data exists
  if (sectionData["Delta"]) {
    attributes.push({
      label: "Delta",
      value: formatPercentage(sectionData["Delta"][0]),
      icon: FaPercentage,
      unit: sectionData["Delta"][1],
      color: sectionData["Delta"][0] < 0 ? 'text-red-600' : 'text-green-600'
    });
  }

  return (
    <FoldableSection title="Access & Size">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        { attributes.map((attribute, index) => (
          <AttributeItem key={ index } { ...attribute } />
        )) }
      </div>
      <AccessInfo title="ROW" content={ sectionData["ROW"][0] } />
      <AccessInfo title="Physical Access" content={ sectionData["Physical Access"][0] } />
      <CommentBlock content={ sectionData["Comments"][0] } />
    </FoldableSection>
  );
};


/**
 * AccessInfo component
 * Displays access information with an icon, title and bullet points
 * @param {string} title - The title of the access info section
 * @param {string} content - The content to display as bullet points
 * @param {Component} icon - Optional icon component, defaults to FaRoad
 */
const AccessInfo = ({ title, content, icon: Icon = FaRoad }) => (
  <div className="mb-4">
    <div className="flex items-center mb-2">
      <Icon className="text-lg text-green-600 mr-2" />
      <h4 className="text-md font-semibold">{ title }</h4>
    </div>
    <ul className="list-disc list-inside text-gray-700 ml-6 space-y-2">
      { content?.split('\n')
        .filter(point => point.trim() !== '')
        .map((point, index) => (
          <li key={ index } className="leading-relaxed">
            { point.trim().replace(/^-\s*/, '') }
          </li>
        )) }
    </ul>
  </div>
);

export default AccessAndSize;