import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const ImplicationsBlock = ({ implications = 'Pending', title = "Implications" }) => {
  return (
    <div className="mb-6 bg-background-subtle border-l-4 border-status-warning p-6 rounded-lg shadow-md transition-default">
      <div className="flex items-center mb-4">
        <FaExclamationTriangle className="text-xl text-status-warning mr-3" />
        <h4 className="text-lg font-heading font-semibold text-text-dark">{title}</h4>
      </div>
      <p className="text-text-primary leading-relaxed">{implications}</p>
    </div>
  );
};

export default ImplicationsBlock;