import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { theme } from '../../../../styles/index.js';
import { chartOptions as baseChartOptions } from './ChartConfig';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ChartContainer = styled.div`
  background: ${theme.common.glassmorphism.background};
  backdrop-filter: ${theme.common.glassmorphism.backdropFilter};
  box-shadow: ${theme.common.glassmorphism.boxShadow};
  padding: ${theme.common.spacing.xl};
  border-radius: 8px;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.lg};
`;

const ChartTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.xs};
`;

const Subtitle = styled.p`
  color: ${theme.colors.text.muted};
  font-size: 0.875rem;
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${theme.common.spacing.md};
  margin-bottom: ${theme.common.spacing.lg};
`;

const MetricBox = styled.div`
  padding: ${theme.common.spacing.md};
  background: ${props => props.color}10;
  border-radius: 6px;
  border: 1px solid ${props => props.color}30;
`;

const MetricLabel = styled.div`
  font-size: 0.75rem;
  color: ${theme.colors.text.muted};
  margin-bottom: 0.25rem;
`;

const MetricValue = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${theme.colors.text.dark};
`;

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
};

const CashflowChart = React.memo(({ years, categories }) => {
  const { projectedReturns, operationalCosts, netCashflow, totalReturns, totalCosts, roi } = useMemo(() => {
    const calculateProjections = () => {
      const totalInvestment = Object.values(categories).reduce((sum, category) => {
        const budget = parseFloat(category.metrics.budget?.replace(/[^0-9.]/g, '') || 0);
        return sum + budget;
      }, 0);

      const projectedReturns = years.map((year, index) => {
        const baseReturnRate = 0.08;
        const growthIncrement = 0.01;
        const returnRate = baseReturnRate + (index * growthIncrement);
        return totalInvestment * (1 + returnRate);
      });

      const operationalCosts = years.map((year, index) => {
        const baseOperationalRate = 0.30;
        const annualInflation = 0.03;
        return totalInvestment * baseOperationalRate * Math.pow(1 + annualInflation, index);
      });

      const netCashflow = projectedReturns.map((returns, index) => 
        returns - operationalCosts[index]
      );

      const totalReturns = projectedReturns.reduce((a, b) => a + b, 0);
      const totalCosts = operationalCosts.reduce((a, b) => a + b, 0);
      const netReturn = totalReturns - totalCosts - totalInvestment;
      const roi = ((netReturn / totalInvestment) * 100).toFixed(1);

      return { 
        projectedReturns, 
        operationalCosts, 
        netCashflow, 
        totalReturns, 
        totalCosts, 
        roi 
      };
    };

    return calculateProjections();
  }, [years, categories]);

  const data = useMemo(() => ({
    labels: years,
    datasets: [
      {
        label: 'Projected Returns',
        data: projectedReturns,
        borderColor: theme.colors.status.success,
        backgroundColor: `${theme.colors.status.success}20`,
        fill: true,
        tension: 0.4
      },
      {
        label: 'Operational Costs',
        data: operationalCosts,
        borderColor: theme.colors.status.error,
        backgroundColor: `${theme.colors.status.error}20`,
        fill: true,
        tension: 0.4
      },
      {
        label: 'Net Cashflow',
        data: netCashflow,
        borderColor: theme.colors.primary,
        backgroundColor: `${theme.colors.primary}20`,
        fill: true,
        tension: 0.4
      }
    ]
  }), [years, projectedReturns, operationalCosts, netCashflow]);

  const finalChartOptions = useMemo(() => ({
    ...baseChartOptions,
    maintainAspectRatio: true,
    aspectRatio: 1.5,
    responsive: true
  }), []);

  return (
    <ChartContainer>
      <Header>
        <ChartTitle>Financial Projections</ChartTitle>
        <Subtitle>5-year forecast with operational costs and returns</Subtitle>
      </Header>

      <MetricsGrid>
        <MetricBox color={theme.colors.status.success}>
          <MetricLabel>Total Projected Returns</MetricLabel>
          <MetricValue>{formatCurrency(totalReturns)}</MetricValue>
        </MetricBox>
        <MetricBox color={theme.colors.status.error}>
          <MetricLabel>Total Operational Costs</MetricLabel>
          <MetricValue>{formatCurrency(totalCosts)}</MetricValue>
        </MetricBox>
        <MetricBox color={theme.colors.primary}>
          <MetricLabel>Expected ROI</MetricLabel>
          <MetricValue>{roi}%</MetricValue>
        </MetricBox>
      </MetricsGrid>

      <Line data={data} options={finalChartOptions} />
    </ChartContainer>
  );
});

CashflowChart.displayName = 'CashflowChart';

export default CashflowChart;
