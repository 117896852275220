import React, { createContext } from 'react';
import { AuthProvider as DescopeAuthProvider } from '@descope/react-sdk';

/**
 * AuthContext for managing authentication state
 */
export const AuthContext = createContext();

/**
 * AuthProvider component to wrap the app and provide authentication context
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} AuthProvider component
 */
export const AuthProvider = ({ children }) => {
  return (
    <DescopeAuthProvider
      projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}
    >
      {children}
    </DescopeAuthProvider>
  );
};