import React, { useState, useEffect } from 'react';
import { fetchAndParseDueDiligenceData } from '../services/notionService';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../styles';

const NOTION_DATABASE_ID = '550971b2b3354d3da9d59a9daf9b5a96';

const DashboardContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.xl};
  padding-bottom: ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
`;


const Title = styled.h1`
  font-size: 2rem;
  color: ${theme.colors.error};
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.1rem;
  color: ${theme.colors.text.primary};
  text-align: center;
`;

/**
 * DueDiligenceGallery component
 * Displays raw due diligence data fetched from Notion
 */
const DueDiligenceGallery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    // Check permissions when component mounts
    const checkPermissions = () => {
      const userPermissions = localStorage.getItem('userPermissions');
      if (userPermissions) {
        try {
          setHasPermission(true);
        } catch (error) {
          setHasPermission(false);
        }
      } else {
        setHasPermission(false);
      }
    };
    checkPermissions();
  }, []);

  useEffect(() => {
    const fetchDueDiligences = async () => {
      try {
        console.log('Fetching due diligences from Notion API...');
        const parsedData = await fetchAndParseDueDiligenceData(NOTION_DATABASE_ID);

        const publicData = parsedData.filter(item => item.viewability === "Public");
        const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '{}');

        if (userPermissions.accessLevel !== 'Selected Access') {
          setParsedData(publicData);
          setLoading(false);
          return;
        }

        const matchData = publicData.filter(record => {
          const allowedDueDiligences = Array.isArray(userPermissions.allowedDueDiligences)
            ? userPermissions.allowedDueDiligences
            : [userPermissions.allowedDueDiligences];

          return allowedDueDiligences.some(portfolio =>
            record.name?.toLowerCase() === portfolio?.toLowerCase()
          );
        });

        setParsedData(matchData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching due diligences:', err);
        setError(`Failed to fetch due diligences: ${err.message}`);
        setLoading(false);
      }
    };

    fetchDueDiligences();
  }, []);

  if (!hasPermission) {
    return (
      <DashboardContainer>
        <Header>
          <Title>Access Denied</Title>
        </Header>
        <Message>You do not have permission to view this content.</Message>
      </DashboardContainer>
    );
  }

  if (loading) return <div className="text-center mt-8">Loading due diligences...</div>;
  if (error) return <div className="text-center mt-8 text-red-600">Error: { error }</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Opportunities Gallery</h1>

      {/* Parsed Data Section */ }
      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          { parsedData.map(item => (
            <Link
              to={ `/due-diligence/${item.id}` }
              key={ item.id }
              className="group relative h-64 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <div
                className="absolute inset-0 bg-cover bg-center transition-transform duration-300 group-hover:scale-105"
                style={ {
                  backgroundImage: `url(${item.imageUrl})`,
                } }
              />
              {/* Dark overlay with gradient */ }
              <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
              {/* Hover overlay */ }
              <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

              <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                <div className="flex justify-between items-end mb-2">
                  <div className="flex-1">
                    <h3 className="font-bold text-xl mb-2 text-white drop-shadow-lg">{ item.name }</h3>
                    <div className="flex flex-col gap-1">
                      <div className="flex items-end">
                        <span className="text-sm drop-shadow-lg font-medium">
                          ${ item.askingPrice?.toLocaleString(undefined, { maximumFractionDigits: 0 }) }
                        </span>
                      </div>
                      <div className="flex items-center text-sm">
                        <span className="drop-shadow-lg">
                          ${ item.askingPricePerHa.toLocaleString(undefined, { maximumFractionDigits: 0 }) } Ha / { ' ' }
                          ${ (item.askingPricePerHa * 2.47105).toLocaleString(undefined, { maximumFractionDigits: 0 }) } Ac
                        </span>
                      </div>
                      <div className="flex items-center text-sm">
                        <span className="drop-shadow-lg">
                          ${ item.propertySizeGIS.toLocaleString(undefined, { maximumFractionDigits: 0 }) } Ha / { ' ' }
                          ${ (item.propertySizeGIS * 2.47105).toLocaleString(undefined, { maximumFractionDigits: 0 }) } Ac
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4">
                    <span className="text-sm bg-white/30 backdrop-blur-sm px-3 py-1 rounded-full font-medium shadow-lg whitespace-nowrap">
                      { item.status }
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          )) }
        </div>
      </div>
    </div>
  );
};

export default DueDiligenceGallery;