import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../styles/index.js';
import ArcGISMap from '../../../MapComponents/ArcGISMap';

const MapContainer = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
  height: 400px;
`;

const MapTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.lg};
  font-size: 1.2rem;
  padding: 0 ${theme.common.spacing.lg};
`;

const MapWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  border-radius: 8px;
  overflow: hidden;
  background: ${theme.colors.background.muted};
`;

const MapView = () => {
  // Using a forestry-focused webmap - this is a sample ID for a topographic map
  const webmapId = "d5e02a0c1f2b4ec399823fdd3c2fdebd";
  // Setting extent to a forested area (example coordinates)
  const extent = "-63.65,44.63,-63.50,44.72"; // Example coordinates for Halifax Nova Scotia

  return (
    <MapContainer>
      <MapTitle>Forest Area Overview</MapTitle>
      <MapWrapper>
        <ArcGISMap 
          webmapId={webmapId}
          extent={extent}
          height="100%"
        />
      </MapWrapper>
    </MapContainer>
  );
};

export default MapView;
