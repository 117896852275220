import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../styles';

const TableContainer = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  margin: 0 -${theme.common.spacing.lg};
  padding: 0 ${theme.common.spacing.lg};
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(10, minmax(120px, 1fr));
  padding: ${theme.common.spacing.md} ${theme.common.spacing.lg};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: all ${theme.common.transitions.fast};
  align-items: center;
  min-width: 1400px;
  background: ${props => props.isSubtotal ? theme.colors.background.muted : 'transparent'};
  
  &:hover {
    background: ${props => props.isHeader ? 'transparent' : theme.colors.background.muted};
    transform: ${props => props.isHeader ? 'none' : 'translateX(4px)'};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const HeaderRow = styled(TableRow)`
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  margin-bottom: ${theme.common.spacing.sm};
  font-weight: 600;
  position: sticky;
  left: 0;
`;

const FooterRow = styled(TableRow)`
  margin-top: ${theme.common.spacing.sm};
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  font-weight: 600;
`;

const Cell = styled.div`
  color: ${props => props.isNegative ? theme.colors.status.error : theme.colors.text.primary};
  font-family: ${props => props.isNumeric ? theme.fonts.mono : theme.fonts.body};
  font-size: 0.9rem;
  text-align: ${props => props.isNumeric ? 'right' : 'left'};
  padding-right: ${props => props.isNumeric ? theme.common.spacing.md : '0'};
  font-weight: ${props => props.isSubtotal || props.isHeader || props.isFooter ? '600' : 'normal'};
  
  /* Make the first column sticky */
  ${props => props.isFirstColumn && `
    position: sticky;
    left: 0;
    background: ${props.isHeader || props.isFooter ? theme.colors.background.muted : 
                props.isSubtotal ? theme.colors.background.muted : 
                theme.colors.background.subtle};
    z-index: 2;
    padding-left: ${theme.common.spacing.lg};
  `}
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.lg};
  font-size: 1.2rem;
  padding: 0 ${theme.common.spacing.lg};
`;

const formatCurrency = (value) => {
  return `$${Math.abs(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const SummaryTable = ({ data }) => {
  const years = Array.from({ length: 10 }, (_, i) => 2024 + i);
  
  return (
    <TableContainer>
      <SectionTitle>Summary</SectionTitle>
      <ScrollContainer>
        <HeaderRow>
          <Cell isFirstColumn isHeader>Category</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isHeader>{year}</Cell>
          ))}
        </HeaderRow>

        {/* Harvesting Section */}
        <TableRow>
          <Cell isFirstColumn>Tonnes SW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>9,754</Cell>
          ))}
        </TableRow>
        <TableRow>
          <Cell isFirstColumn>Tonnes HW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>12,835</Cell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Tonnes Total</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>22,588</Cell>
          ))}
        </TableRow>

        {/* Stumpage Section */}
        <TableRow>
          <Cell isFirstColumn>Av. Stumpage SW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>{formatCurrency(26.22)}</Cell>
          ))}
        </TableRow>
        <TableRow>
          <Cell isFirstColumn>Av. Stumpage HW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>{formatCurrency(9.27)}</Cell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Av. Stumpage</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>{formatCurrency(16.59)}</Cell>
          ))}
        </TableRow>

        {/* Sales Section */}
        <TableRow>
          <Cell isFirstColumn>Stumpage Sales SW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>{formatCurrency(255732.08)}</Cell>
          ))}
        </TableRow>
        <TableRow>
          <Cell isFirstColumn>Stumpage Sales HW</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric>{formatCurrency(118942.59)}</Cell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Stumpage Sales Total</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>{formatCurrency(374674.67)}</Cell>
          ))}
        </TableRow>

        {/* Funding Section */}
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Funding</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>{formatCurrency(488546.90)}</Cell>
          ))}
        </TableRow>

        {/* Costs Section */}
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Road Construction</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>{formatCurrency(0)}</Cell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Silviculture</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isSubtotal>{formatCurrency(488546.90)}</Cell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <Cell isFirstColumn isSubtotal>Costs Total</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isNegative isSubtotal>{formatCurrency(119192.00)}</Cell>
          ))}
        </TableRow>
        <TableRow>
          <Cell isFirstColumn>Costs per Tonne</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isNegative>{formatCurrency(5.28)}</Cell>
          ))}
        </TableRow>

        {/* Profit Section */}
        <FooterRow>
          <Cell isFirstColumn isFooter>Gross Profit</Cell>
          {years.map(year => (
            <Cell key={year} isNumeric isNegative={249550.67 < 0} isFooter>{formatCurrency(249550.67)}</Cell>
          ))}
        </FooterRow>
      </ScrollContainer>
    </TableContainer>
  );
};

export default SummaryTable;
