import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../styles/index.js';

const Card = styled.div`
  background: ${theme.colors.background.light};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 16px;
  padding: ${theme.common.spacing.lg};
  width: 100%;
  min-width: 280px;
  max-width: 100%;
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  
  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
`;

const CategoryLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.color || theme.colors.primary};
  font-weight: 600;
  font-size: 0.8125rem;
  margin-bottom: ${theme.common.spacing.xs};
  letter-spacing: -0.01em;
`;

const MainMetric = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${theme.colors.text.dark};
  margin: ${theme.common.spacing.sm} 0;
  letter-spacing: -0.02em;
  line-height: 1.1;
`;

const Trend = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 6px;
  background: ${props => props.isPositive ? 'rgba(52, 211, 153, 0.1)' : 'rgba(239, 68, 68, 0.1)'};
  color: ${props => props.isPositive ? 'rgb(52, 211, 153)' : 'rgb(239, 68, 68)'};
`;

const DetailsList = styled.div`
  margin-top: ${theme.common.spacing.md};
  transition: all 0.3s ease;
  opacity: ${props => props.isExpanded ? 1 : 0};
  max-height: ${props => props.isExpanded ? '1000px' : '0'};
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${theme.common.spacing.sm} 0;
  font-size: 0.875rem;
  width: 100%;
  border-bottom: ${props => props.$hasSubItems ? 'none' : '1px solid rgba(0, 0, 0, 0.12)'};
  
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  & + div {
    margin-top: ${theme.common.spacing.xs};
  }
`;

const DetailLabel = styled.span`
  color: ${theme.colors.text.dark};
  font-weight: 400;
  flex: 1;
  margin-right: ${theme.common.spacing.md};
`;

const DetailValue = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  text-align: right;
  color: ${theme.colors.text.muted};
`;

const SubDetailsList = styled.div`
  padding-left: ${theme.common.spacing.md};
  margin: ${theme.common.spacing.xs} 0;
  width: 100%;
  
  ${DetailItem} {
    padding: ${theme.common.spacing.xs} 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Badge = styled.span`
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  background: ${props => props.background || `${props.color}15` || `${theme.colors.primary}15`};
  color: ${props => props.color || theme.colors.primary};
  white-space: nowrap;
  min-width: fit-content;
`;

const MetricCard = ({ category, color, metrics }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <SubDetailsList>
          {value.map((item, idx) => (
            <DetailItem key={idx}>
              {typeof item === 'object' ? (
                <>
                  <DetailLabel>{item.name || item.type}</DetailLabel>
                  <DetailValue>
                    {((item.amount || item.length) || item.status) && (
                      <Badge color={color}>
                        {item.amount || item.length || item.status}
                      </Badge>
                    )}
                  </DetailValue>
                </>
              ) : (
                <>
                  <DetailLabel>{item}</DetailLabel>
                  <DetailValue>
                    <Badge color={color}>{item}</Badge>
                  </DetailValue>
                </>
              )}
            </DetailItem>
          ))}
        </SubDetailsList>
      );
    }
    if (typeof value === 'object') {
      return (
        <SubDetailsList>
          {Object.entries(value).map(([key, val]) => (
            <DetailItem key={key}>
              <DetailLabel>{key}</DetailLabel>
              <DetailValue>{val}</DetailValue>
            </DetailItem>
          ))}
        </SubDetailsList>
      );
    }
    return value;
  };

  const mainMetric = Object.entries(metrics)[0];
  const trend = metrics.trend;
  const isPositiveTrend = trend?.startsWith('+');

  return (
    <Card
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <CategoryLabel color={color}>
        <span>{category}</span>
        {trend && (
          <Trend isPositive={isPositiveTrend}>
            {trend}
          </Trend>
        )}
      </CategoryLabel>
      
      <MainMetric>
        {mainMetric[1]}
      </MainMetric>
      
      <DetailsList isExpanded={isExpanded}>
        {Object.entries(metrics)
          .filter(([key]) => !['trend'].includes(key))
          .map(([key, value], index, array) => (
            <React.Fragment key={key}>
              <DetailItem $hasSubItems={typeof value === 'object'}>
                <DetailLabel>
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}:
                </DetailLabel>
                <DetailValue>
                  {typeof value !== 'object' && value}
                </DetailValue>
              </DetailItem>
              {typeof value === 'object' && renderValue(value)}
            </React.Fragment>
          ))}
      </DetailsList>
    </Card>
  );
};

export default MetricCard;
