import React from 'react';
import { theme } from '../../styles';

/**
 * Modern Footer component with glassmorphism effect
 * Uses flexbox to stick to the bottom of the page
 * 
 * @returns {React.Element} A styled footer component with links and copyright information
 */
const Footer = () => {
  const footerStyle = {
    ...theme.common.glassmorphism,
    color: theme.colors.text.light,
    backgroundColor: theme.colors.primary,
    width: '100%',
  };

  const linkStyle = {
    color: theme.colors.text.light,
    transition: 'opacity 0.3s ease',
  };

  return (
    <footer style={ footerStyle }>
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2" style={ { color: theme.colors.text.light } }>
              OA Forest Hub
            </h3>
            <p className="text-sm opacity-80">&copy; 2024 Ovis Analytics. All rights reserved.</p>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            { [
              { label: 'Privacy Policy', href: '/privacy-policy' },
              { label: 'Terms of Service', href: '/terms-of-service' },
              { label: 'Contact Us', href: '/contact' }
            ].map((link, index) => (
              <a
                key={ index }
                href={ link.href }
                className="hover:opacity-70 transition duration-300"
                style={ linkStyle }
              >
                { link.label }
              </a>
            )) }
          </div>
        </div>
        <div className="mt-4 text-center md:text-left">
          <p className="text-sm opacity-60">
            Creating forest opportunities, one click at a time.
          </p>
          <div className="mt-2">
            <svg
              className="inline-block w-6 h-6"
              fill="none"
              stroke={ theme.colors.text.light }
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Leaf icon"
              style={ { opacity: 0.8 } }
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={ 2 }
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;