export const years = ['2024', '2025', '2026', '2027', '2028'];

export const categories = {
  harvesting: {
    label: 'Harvesting',
    color: '#4CAF50',
    metrics: {
      totalVolume: '125,000 m³',
      trend: '+8%',
      budget: '$4.2M',
      operations: [
        { name: 'Block A-23', volume: '45,000m³', status: 'In Progress', completion: '65%' },
        { name: 'Block B-12', volume: '38,000m³', status: 'Planned', completion: '0%' },
        { name: 'Block C-7', volume: '42,000m³', status: 'Complete', completion: '100%' }
      ],
      productivity: '850m³/day',
      speciesDistribution: {
        softwood: '75%',
        hardwood: '25%'
      }
    }
  },
  funding: {
    label: 'Funding',
    color: '#2196F3',
    metrics: {
      totalSecured: '$15.8M',
      trend: '+12%',
      grants: [
        { name: 'Sustainable Forestry Grant', amount: '$6.5M', status: 'Secured' },
        { name: 'Carbon Credit Program', amount: '$4.8M', status: 'Secured' },
        { name: 'Innovation Fund', amount: '$4.5M', status: 'Pending' }
      ],
      roi: '22%',
      carbonCredits: '45,000 tons',
      nextMilestone: 'Q3 2024'
    }
  },
  roadConstruction: {
    label: 'Road Construction',
    color: '#FF9800',
    metrics: {
      totalKm: '28.5 km',
      trend: '+15%',
      budget: '$3.2M',
      segments: [
        { name: 'Main Access Road', length: '12.5km', status: 'In Progress', completion: '75%' },
        { name: 'Harvest Block Access', length: '8.3km', status: 'Planned', completion: '0%' },
        { name: 'Secondary Routes', length: '7.7km', status: 'Complete', completion: '100%' }
      ],
      maintenanceCost: '$380K/year',
      permitStatus: 'Approved'
    }
  },
  silviculture: {
    label: 'Silviculture',
    color: '#9C27B0',
    metrics: {
      treatedArea: '850 ha',
      trend: '+18%',
      budget: '$2.1M',
      treatments: [
        { name: 'Site Preparation', area: '320ha', status: 'In Progress' },
        { name: 'Thinning', area: '280ha', status: 'Planned' },
        { name: 'Brush Control', area: '250ha', status: 'Complete' }
      ],
      survivalRate: '92%',
      costPerHectare: '$2,470'
    }
  },
  planting: {
    label: 'Planting',
    color: '#607D8B',
    metrics: {
      totalTrees: '750,000',
      trend: '+20%',
      budget: '$1.8M',
      species: [
        { name: 'White Spruce', quantity: '300,000', status: 'Scheduled' },
        { name: 'Black Spruce', quantity: '250,000', status: 'In Progress' },
        { name: 'Jack Pine', quantity: '200,000', status: 'Complete' }
      ],
      densityPerHa: '2,000',
      stockingRate: '95%'
    }
  },
  management: {
    label: 'Management',
    color: '#795548',
    metrics: {
      totalFees: '$1.2M',
      trend: '+5%',
      budget: '$1.2M',
      components: [
        { name: 'Administrative Overhead', amount: '$450K', type: 'Fixed' },
        { name: 'Professional Services', amount: '$380K', type: 'Variable' },
        { name: 'Technical Support', amount: '$370K', type: 'Variable' }
      ],
      percentageOfRevenue: '8.5%',
      staffing: {
        forestry: '6',
        admin: '3',
        technical: '4'
      }
    }
  }
};
