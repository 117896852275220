import React, { useState, useEffect } from 'react';
import TimelineView from '../components/Portfolio/StatusUpdates/TimelineView';
import AnnualReview from '../components/Portfolio/FinancialReview/FinancialReview';
import ManagementPlan from '../components/Portfolio/ManagementPlan/ManagementPlan';
import SearchForm from '../components/BaseComponents/SearchForm';
import SwitchSelector from '../components/BaseComponents/SwitchSelector';
import { FaChartLine, FaCoins } from 'react-icons/fa';
import TimelineFilter from '../components/Portfolio/StatusUpdates/TimelineFilter';
import { fetchAndParseAirtableRecords } from '../services/airtableService';
import LandownerFilter from '../components/Portfolio/StatusUpdates/LandownerFilter';

const portfolioUpdateBaseId = "appOzTtsysmPCXcnP";
const portfolioUpdateTableId = "tbl1Hekyfan3HYO3M";
const tableId = 'tblQAuqWadLwtvY05';


const viewOptions = [
  { value: 'Timeline', label: 'Status Updates', icon: FaChartLine },
  { value: 'Annual Review', label: 'Financial Review', icon: FaCoins },
  { value: 'Management Plan', label: 'Management Plan', icon: FaChartLine },
];

export default function ForestInvestmentTimeline() {
  const [portfolioFilter, setPortfolioFilter] = useState('');
  const [activeView, setActiveView] = useState('Timeline');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedLandowners, setSelectedLandowners] = useState([]);
  const [landownerOptions, setLandownerOptions] = useState([]);
  const [userPermissions, setUserPermissions] = useState(null);


  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const storedPermissions = localStorage.getItem('userPermissions');
        if (storedPermissions) {
          const permissions = JSON.parse(storedPermissions);
          setUserPermissions({
            accessLevel: permissions.accessLevel,
            allowedPortfolio: permissions.allowedPortfolio
          });
        }
      } catch (error) {
        console.error('Error getting user permissions:', error);
      }
    };

    fetchUserPermissions();
  }, []);

  useEffect(() => {
    if (activeView === 'Timeline') {
      const fetchCategories = async () => {
        try {
          const records = await fetchAndParseAirtableRecords(
            portfolioUpdateBaseId,
            portfolioUpdateTableId,
            'Published = 1'
          );

          // Split the Landowner and LandownerName strings into arrays
          let landownerData = records.map(element => {
            const landowners = Array.isArray(element.Landowner)
              ? element.Landowner
              : element.Landowner.split(',').map(id => id.trim());

            const landownerNames = element.LandownerName.split(',').map(name => name.trim());

            // Create array of objects combining IDs with names
            return landowners.map((id, index) => ({
              value: id,
              label: landownerNames[index] || ''
            }));
          }).flat();

          // Filter landowners based on user permissions
          if (userPermissions?.accessLevel === 'Selected Access') {
            const allowedPortfolios = userPermissions.allowedPortfolio || [];
            landownerData = landownerData.filter(landowner =>
              allowedPortfolios.includes(landowner.label)
            );
          }

          const uniqueLandowners = Array.from(
            new Set(landownerData.map(JSON.stringify))
          ).map(JSON.parse);

          setLandownerOptions(uniqueLandowners);

          const uniqueCategories = [...new Set(records.map(record => record.Type))].filter(Boolean);
          setCategories(uniqueCategories);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      fetchCategories();
    }
  }, [activeView, userPermissions]);



  useEffect(() => {
    const fetchLandowners = async () => {
      try {
        const records = await fetchAndParseAirtableRecords(
          portfolioUpdateBaseId,
          tableId,
        );

        let landownerValues = records.map(record => ({
          id: Array.isArray(record.Landowner) ? record.Landowner[0] : record.Landowner,
          name: record.LandownerName || 'Unknown'
        }));

        // Filter landowners based on user permissions
        if (userPermissions?.accessLevel === 'Selected Access') {
          const allowedPortfolios = userPermissions.allowedPortfolio || [];
          landownerValues = landownerValues.filter(landowner =>
            allowedPortfolios.includes(landowner.name)
          );
        }

        const uniqueLandowners = Array.from(
          new Set(
            landownerValues
              .filter(item => item.id)
              .map(JSON.stringify)
          )
        )
          .map(JSON.parse)
          .sort((a, b) => a.name.localeCompare(b.name));

        const formattedLandowners = uniqueLandowners.map(landowner => ({
          value: landowner.id,
          label: landowner.name
        }));

        setLandownerOptions(formattedLandowners);
      } catch (error) {
        console.error('Error fetching landowners:', error);
      }
    };

    if (userPermissions) {
      fetchLandowners();
    }
  }, [userPermissions]);

  return (
    <>
      <div className="fixed top-20 left-0 right-0 z-10">
        <div className="grid grid-cols-3 items-center px-4">
          <div className="flex justify-start">
            { userPermissions && activeView === 'Timeline' && (
              <TimelineFilter
                categories={ categories }
                selectedCategories={ selectedCategories }
                onChange={ setSelectedCategories }
              />
            ) }
          </div>

          <div className="flex justify-center">
            <SwitchSelector
              options={ viewOptions }
              activeOption={ activeView }
              onSwitch={ setActiveView }
              size="small"
            />
          </div>

          <div className="flex justify-end">
            { userPermissions && (
              <>
                { activeView === 'Timeline' ? (
                  <LandownerFilter
                    selectedLandowners={ selectedLandowners }
                    onLandownerChange={ setSelectedLandowners }
                    landownerOptions={ landownerOptions }
                  />
                ) : activeView === 'Annual Review' ? (
                  <SearchForm
                    selectedLandowners={ selectedLandowners }
                    onLandownerChange={ setSelectedLandowners }
                    landownerOptions={ landownerOptions }
                  />
                ) : null }
              </>
            ) }
          </div>
        </div>
      </div>

      { activeView === 'Timeline' ? (
        <TimelineView
          searchTerm={ portfolioFilter }
          selectedCategories={ selectedCategories }
          selectedLandowners={ selectedLandowners }
        />
      ) : activeView === 'Management Plan' ? (
        <ManagementPlan />
      ) : (
        <AnnualReview selectedLandowners={ selectedLandowners } />
      ) }
    </>
  );
}