import React from 'react';
import { FaLightbulb } from 'react-icons/fa';

const CommentBlock = ({ content = '', title = "Ovis Expert Insights" }) => {
  const bulletPoints = content?.includes('\n') 
    ? content.split('\n').filter(point => point.trim() !== '')
    : [content];

  return (
    <div className="mb-6 bg-background-subtle border-l-4 border-primary p-6 rounded-lg shadow-md transition-default">
      <div className="flex items-center mb-4">
        <FaLightbulb className="text-xl text-primary mr-3" />
        <h4 className="text-lg font-heading font-semibold text-text-dark">{title}</h4>
      </div>
      {bulletPoints.length > 1 ? (
        <ul className="list-disc list-inside text-text-primary space-y-2">
          {bulletPoints.map((item, index) => (
            <li key={index} className="leading-relaxed">{item.trim().replace(/^-\s*/, '')}</li>
          ))}
        </ul>
      ) : (
        <p className="text-text-primary italic leading-relaxed">{content}</p>
      )}
    </div>
  );
};

export default CommentBlock;