import React from 'react';
import { Link } from 'react-router-dom';
import { FaTree, FaSearchPlus, FaHandshake, FaChartLine } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { theme } from '../styles/index';
import '../styles/index.css';

const Section = ({ children, className = '' }) => (
  <section className={`py-16 px-4 ${className}`}>
    <div className="max-w-7xl mx-auto">
      {children}
    </div>
  </section>
);

const CapabilityCard = ({ icon: Icon, title, description, index }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
  >
    <motion.div 
      className="p-8 bg-background-light rounded-xl shadow-lg flex flex-col items-center h-full relative overflow-hidden group" 
      style={{ backgroundColor: theme.colors.background.light }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <motion.div 
        className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-5 transition-opacity duration-300"
        style={{ backgroundColor: theme.colors.primary }}
      />
      <motion.div 
        className="mb-6 text-primary relative"
        style={{ color: theme.colors.primary }}
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 400 }}
      >
        <Icon size={42} />
      </motion.div>
      <h3 className="text-xl font-bold mb-4 text-center relative">{title}</h3>
      <p className="text-text-primary text-center relative" style={{ color: theme.colors.text.primary }}>{description}</p>
    </motion.div>
  </motion.div>
);

const Capabilities = () => {
  const capabilities = [
    {
      icon: FaTree,
      title: "Find Forest Investment Opportunities",
      description: "Discover and invest in lucrative forest opportunities tailored to your investment goals."
    },
    {
      icon: FaSearchPlus,
      title: "Get Tailored, Rapid Forest Due Diligences",
      description: "Receive comprehensive and swift due diligence reports to make informed decisions."
    },
    {
      icon: FaHandshake,
      title: "Sell Your Forest to Sustainable Investors",
      description: "Connect with investors who value sustainability and get the best value for your forest."
    },
    {
      icon: FaChartLine,
      title: "World-Class Forest Portfolio Reporting",
      description: "Access detailed reports and insights to manage and optimize your forest portfolio."
    }
  ];

  return (
    <Section className="bg-background-subtle" style={{ backgroundColor: theme.colors.background.subtle }}>
      <motion.div 
        className="max-w-8xl mx-auto mb-12 text-center"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Our Capabilities</h2>
        <p className="text-lg text-text-primary" style={{ color: theme.colors.text.primary }}>
          Comprehensive tools and services for forest investment management
        </p>
      </motion.div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {capabilities.map((capability, index) => (
          <CapabilityCard key={index} {...capability} index={index} />
        ))}
      </div>
    </Section>
  );
};

const Home = () => {
  const bannerStyle = {
    ...theme.common.glassmorphism,
    backgroundImage: 'url("https://images.unsplash.com/photo-1426170042593-200f250dfdaf?q=80&w=2560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="min-h-screen">
      {/* Banner Section */}
      <section className="h-screen flex items-center justify-center" style={bannerStyle}>
        <div className="glassmorphism p-8 rounded-lg max-w-5xl mx-4">
          <h1 className="text-5xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Data-Driven Forest Investing</h1>
          <p className="text-xl text-text-primary mb-8" style={{ color: theme.colors.text.primary }}>We drive the future of sustainable investing with smart opportunity analysis and next-generation portfolio reporting.</p>
          <div className="flex gap-4">
            <Link to="/due-diligences" className="bg-primary text-text-light px-6 py-3 rounded-lg hover:bg-primaryDark transition-default" style={{ backgroundColor: theme.colors.primary, color: theme.colors.text.light }}>Explore Forests</Link>
            <Link to="/login" className="border-2 border-primary text-primary px-6 py-3 rounded-lg hover:bg-primary hover:text-white transition-default" style={{ borderColor: theme.colors.primary, color: theme.colors.primary }}>Log In</Link>
          </div>
        </div>
      </section>

      {/* Capabilities Section */}
      <Capabilities />

      {/* Investment Opportunities Section */}
      <Section>
        <div className="flex flex-col md:flex-row items-center gap-12">
          <div className="flex-1">
            <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Review Investment Opportunities</h2>
            <p className="text-lg text-text-primary mb-6" style={{ color: theme.colors.text.primary }}>Discover thoroughly vetted forest investment opportunities with our comprehensive due diligence process.</p>
            <Link to="/due-diligence" className="bg-primary text-text-light px-6 py-3 rounded-lg hover:bg-primaryDark transition-default inline-block" style={{ backgroundColor: theme.colors.primary, color: theme.colors.text.light }}>Explore Due Diligence</Link>
          </div>
          <div className="flex-1">
            {/* Placeholder for image or illustration */}
            <div className="bg-background-muted h-64 rounded-lg" style={{ backgroundColor: theme.colors.background.muted }}></div>
          </div>
        </div>
      </Section>

      {/* Sell Your Forest Section */}
      <Section className="bg-background-subtle" style={{ backgroundColor: theme.colors.background.subtle }}>
        <div className="flex flex-col md:flex-row-reverse items-center gap-12">
          <div className="flex-1">
            <h2 className="text-4xl font-bold text-text-dark mb-4" style={{ color: theme.colors.text.dark }}>Sell Your Forest</h2>
            <p className="text-lg text-text-primary mb-6" style={{ color: theme.colors.text.primary }}>Looking to sell your forest? We offer competitive valuations and a seamless transaction process.</p>
            <Link to="/contact" className="bg-primary text-text-light px-6 py-3 rounded-lg hover:bg-primaryDark transition-default inline-block" style={{ backgroundColor: theme.colors.primary, color: theme.colors.text.light }}>Contact Us</Link>
          </div>
          <div className="flex-1">
            {/* Placeholder for image or illustration */}
            <div className="bg-background-muted h-64 rounded-lg" style={{ backgroundColor: theme.colors.background.muted }}></div>
          </div>
        </div>
      </Section>

      {/* Portfolio Reporting Section */}
      <Section>
        <h2 className="text-4xl font-bold text-text-dark mb-8 text-center" style={{ color: theme.colors.text.dark }}>World-Class Portfolio Reporting</h2>
        <div className="grid md:grid-cols-4 gap-6">
          {['ESG', 'Carbon', 'Biodiversity', 'Investor'].map((category) => (
            <div key={category} className="p-6 bg-background-light rounded-lg shadow-md text-center" style={{ backgroundColor: theme.colors.background.light }}>
              <h3 className="text-xl font-bold mb-4">{category} Reporting</h3>
              <p className="text-text-primary" style={{ color: theme.colors.text.primary }}>Comprehensive {category.toLowerCase()} metrics and analysis</p>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default Home;